<template>
    <van-nav-bar title="消息详情" left-arrow :fixed="true" :placeholder="true" @click-left="onClickLeft" />
    <van-cell>
        <div style="font-weight: bold;font-size: 16px;margin-top: 15px;">标题：{{ list.title }}</div>
        <div style="margin-top: 30px;font-size: 0.4rem;">时间：{{ list.createTime }}</div>
        <div v-html="change(list.content)" class="pstysd"></div>
    </van-cell>
</template>
<script>
export default {
    name: 'messageDetails',
    data() {
        return {
            list: JSON.parse(this.$route.query.items),
        }
    },
    created() {

    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        change(strValue) {
            // console.log(strValue,'1');
            strValue = strValue.replace(/&amp;/g, "&");
            strValue = strValue.replace(/&lt;/g, "<");
            strValue = strValue.replace(/&gt;/g, ">");
            strValue = strValue.replace(/&quot;/g, "\"");
            strValue = strValue.replace(/&#39;/g, "'");
            // console.log(strValue,'2');
            return strValue;
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar__arrow {
    font-size: 46px !important;
    padding-top: 10px;
    color: #000;
}

::v-deep .van-nav-bar__title {
    color: black !important;
    font-weight: bold !important;
    font-size: 30px !important;
    padding: 30px 0;

}

::v-deep(.van-nav-bar__title) {
    font-weight: bold !important;
    font-size: 30px !important;
    height: 60px;
}

::v-deep(.van-nav-bar__content) {
    height: 100px;
    line-height: 80px;
    margin: auto;
    background: #fff;
}

.pstysd {
    line-height: 1.5;
    // overflow: hidden;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 3;
    margin-top: 50px;

    img {
        width: 100%;
    }
}
</style>
